import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Title, Wrappers } from "../../../components/elements/UserContentTemplete";

const SubTitle = styled.div`
font-weight:bold;
margin-bottom:16px;
font-size:${props => props.theme.size.font3};
`
const Text = styled.div`
margin-bottom:24px;
font-size:${props => props.theme.size.font4};
line-height:20px;
`
const Policy = (props) => {
    const params = useParams();
    const [title, setTitle] = useState("");
    useEffect(() => {
        if (params.pk == 0) {
            setTitle("이용약관");
        } else if (params.pk == 1) {
            setTitle("개인정보처리방침");
        } else if (params.pk == 2) {
            setTitle("저작권정책");
        }

    }, [params])

    return (
        <>
            <Wrappers style={{ margin: `${props?.pk >= 0 ? '0' : ''}` }}>
                <Title not_arrow={true}>{title}</Title>
                {params.pk == 0 || props?.pk == 0 ?
                    <>
                        <SubTitle>1. 서비스 이용약관</SubTitle>

                        <Text>
                            주식회사 오앤유 페이먼트(이하 “달카페이”또는 “회사”)는 월세 계약체결 카드결제 서비스
                            이용부터 종료까지 이용조건 및 절차, 계약 시스템 간 권리, 의무 및 기타 필요한 사항을 규
                            정함을 목적으로 합니다.<br />
                            귀하가 본 약관에 동의하는 것을 조건으로 서비스를 제공할 것입니다.<br /><br />

                            1) 본 약관을 변경하는 경우 이용자가 직접 확인할 수 있도록 시스템 내 공지사항을 공지
                            합니다. 이용자가 변경 약관에 동의하지 않는 경우 본인의 회원등록을 취소(회원탈퇴)할
                            수 있으며, 계속 사용의 경우에는 변경 약관에 대한 동의로 간주합니다.
                            부동산 월세 계약체결 이외의 다른 용도로는 이용되지 않으며, 계약 서비스 이용을 위한
                            최소한의 개인정보를 수집합니다.<br />
                            (1) 회사는 부동산 월세 카드결제를 위한 전반적인 정보 제공서비스를 합니다.<br />
                            (2) 회사는 상품정보 제공목적 및 이용자에게 이메일, 문자메시지(SMS), 푸시 알림톡 등을
                            발송할 수 있습니다.<br /><br />
                            2) 회원 수정이 필요한 경우 즉시 온라인 수정을 하거나 기타 방법으로 회사에 변경사항을
                            알려야 하며 알리지 않아 발생한 불이익에 대해서는 책임지지 않습니다.<br /><br />
                            3) 회사는 이용자가 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실에 관하여서는
                            책임지지 않습니다.<br /><br />
                        </Text>

                        <SubTitle>2. 개인정보 처리방침</SubTitle>
                        <Text>
                            1) 회원의 개인정보는 다음과 같이 수집, 사용, 관리, 보호됩니다.<br />
                            (1) 회원가입 – 회원ID, 비밀번호, 휴대폰번호, 이름, 주소, 비밀번호, 계좌정보 (단, 서비스
                            탈퇴 시 즉시 파기)공인중개사 경우 회원의 성명, 주소, 전화번호, 공인중개사 등록번호
                            등을 포함합니다.<br />
                            (2) 개인정보 수집 및 이용-부동산거래 계약의 본 서비스는 다음의 목적을 위해 개인정보
                            를 수집 및 이용합니다. 수집된 개인정보는 아래의 목적 이외의 용도로는 이용되지 않
                            으며 수집목적이 변경될 경우 사전에 알리고 동의를 받을 예정입니다.<br />
                            (3) 외부기업이나 중개소를 통하며 개인정보를 제공받을 수 있으며, 이런 경우 개인정보
                            보호법에 따라 이용자의 동의를 받은 후 주식회사 오앤유 페이먼트(“달카페이“)에 제공
                            합니다. 팩스 또는 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.<br />
                            (4) 계약 시의 민원처리나 이용자의 민원 내용확인, 세부내용 확인과 조사를 위한 연락처,
                            처리결과 통보, 분쟁조정을 위한 기타 고지사항 전달 등을 목적으로 개인정보를 수집하
                            고 처리합니다. 민원처리로 인한 전화문의 시 휴대전화번호, 녹취테이터, 문의 내용을
                            수집할 수 있습니다(소비자의 불만 분쟁 처리로 인한 기록 보관은 3년).<br /><br />
                            2) 회사는 이용자 상호간 및 이용자와 제3자 사이에 발생한 분쟁에 개입하지 않으며, 이로
                            인한 손해를 책임지지 않습니다.<br /><br />
                        </Text>
                        <SubTitle>3. 개인정보 처리방침 및 제3자 개인정보 활용 동의 </SubTitle>
                        <Text>
                            1) 회사는 이용자의 개인정보를 개인정보의 처리목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정등 `개인정보 보호법`제 17조 및 18조에 해당하는 경
                            우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보 주체의 개인정보를 제3자에게
                            제공하지 않습니다.<br /><br />
                            2) 개인정보 제공에 사전 동의한 경우에 한하여 개인정보를 제공하고 있으며, 개인정보의
                            제3자가 제공에 대한 동의를 거부하시는 경우에도 기본 서비스를 이용하고 있습니다. 단
                            이용자의 문의와 불만 처리시 까지 연락처 등의 개인정보 보유로 업무 내용과 위탁하는
                            이용자 서비스 내용을 언제든지 쉽게 확인할 수 있도록 회사의 개인정보 방침에 지속적으
                            로 정보를 공개합니다.<br /><br />
                            3) 회사의 서비스 업무내용이나 변경시 개인정보 처리방침에 따라 지속적으로 공개를 합니
                            다.<br /><br />
                        </Text>
                        <SubTitle>4. 서비스 제공의 중단, 중지, 이용제한 등</SubTitle>
                        <Text>
                            1) 서비스의 이용기간은 회사에 서비스를 신청하여 이용 승낙이 있는 날로부터 이용계약
                            해지 시까지입니다. 서비스 이용시간은 연중무휴, 1일 24시간으로 원칙으로 하며 단 업무,
                            기술상의 특별한 사정으로 수시점검이 필요한 날 또는 시간은 예외로 합니다. 계약 시스
                            템이 통제할 수 없는 불가항력적인 사유로 인하여 서비스가 중단(디스크 장애 및 시스템
                            다운, 데이터삭제) 등이 발생한 경우 계약시스템에 대한 책임을 부담하지 않습니다. 정상
                            적인 서비스 제공이 어려운 경우 일시적으로 서비스를 긴급으로 일시적으로 중지할 수 있
                            습니다.<br /><br />
                            2) 계약 시스템상 회원 귀책사유로 인한 서비스 이용장애 대해서는 책임지지 않습니다. 본 서비스계약에서 제공하는 서비스가 아닌 사항에 대해서는 내용상의 정확성, 안전성을
                            보장하지 않으며, 회원이 위 내용을 이용함에 따른 모든 손해에 대하여 책임을 부담하지
                            않습니다. 이용자는 개인의 정보를 최신의 상태로 입력하여 입력한 부정확한 정보로 발생
                            하는 사고의 책임은 이용자 자신에게 있으며 타인정보의 도용, 허위정보를 입력할 경우
                            회원 자격이 상실될 수 있습니다. 회원이 계약시스템을 통해 작성, 전송, 입수한 모든 형
                            태의 정보에 대해서는 회원 본인이 모든 책임을 부담합니다.<br /><br />
                            3) 정상적인 서비스 중지에 따른 미전송, 미보관 등이 발생한 경우 회원(이용자 포함) 및
                            제재에 대하여 책임을 부담하지 않습니다.<br /><br />
                            4) 시스템계약 건이 영구적으로 서비스를 중대한 사정이 발생한 경우, 중단절차를 진행하
                            되 사전공지를 합니다.<br /><br />
                            5) 개인정보를 본인승낙없이 타인에게 누설, 배포하지 않습니다.<br /><br />
                            6) 개인정보의 열람, 정정, 삭제처리 정지요구 및 불만처리 문의에 대한 창구는 아래와 같
                            습니다.<br /><br />
                            - 고객센터 대표번호: 1533-8643 / 팩스번호: 031)624-4396
                            (고객센터 운영시간 평일 오전 10시부터 오후 6시. 토요일, 공휴일은 휴무입니다) - 이메일: dalcapay@daum.net7)
                            회원 가입정보는 정확하게 기재하여야 하며, 회원정보가 정확한 상태로 유지되도록 변
                            경내용이 발생한 경우 이를 즉시 반영하여야 합니다. 회원은 자신의 아이디 및 비밀번호
                            를 제3자에게 이용하게 해서는 안 됩니다.
                            회원은 회사의 사전의 승낙없이 서비스를 이용
                            한 영리활동을 할 수 없습니다.
                            이용자의 귀책사유로 이용계약이 해지되는 경우, 이용계약
                            의 해지는 손해배상의 청구에 영향을 미치지 않습니다.
                            회사 또는 제3자에게 손해를 발생
                            한 경우 손해배상을 하여야 합니다.
                        </Text>

                        <SubTitle>5. 회사의 의무사항</SubTitle>
                        <Text>
                            1) 지속적이고 안전한 서비스 제공에 최선을 다합니다.<br /><br />
                            2) 이용자 상호간의 거래행위에 발생하는 문제 및 손실에 대해서는 일체 책임을 부담하지
                            않으며 거래당사자간 직접 해결해야 합니다.<br /><br />
                            3) 회사는 서비스 운영과 제공하는 서비스의 화면에 광고를 개제 할 수 있습니다.<br /><br />
                            4) 회사는 최소한의 개인정보를 수집, 이용하며 근거없는 제3자에게 회원의 정보를 제공하
                            지 않습니다.<br /><br />
                        </Text>
                    </>
                    :
                    <>
                    </>}
                {params.pk == 1 || props?.pk == 1 ?
                    <>
                        <SubTitle>1. 개인정보 처리방침 및 제3자 개인정보 활용 동의 </SubTitle>
                        <Text>
                            1) 회원의 개인정보는 다음과 같이 수집, 사용, 관리, 보호됩니다.<br />
                            (1) 회원가입 – 회원ID, 비밀번호, 휴대폰번호, 이름, 주소, 비밀번호, 계좌정보 (단, 서비스
                            탈퇴 시 즉시 파기)공인중개사 경우 회원의 성명, 주소, 전화번호, 공인중개사 등록번호
                            등을 포함합니다.<br />
                            (2) 개인정보 수집 및 이용-부동산거래 계약의 본 서비스는 다음의 목적을 위해 개인정보
                            를 수집 및 이용합니다. 수집된 개인정보는 아래의 목적 이외의 용도로는 이용되지 않
                            으며 수집목적이 변경될 경우 사전에 알리고 동의를 받을 예정입니다.<br />
                            (3) 외부기업이나 중개소를 통하며 개인정보를 제공받을 수 있으며, 이런 경우 개인정보
                            보호법에 따라 이용자의 동의를 받은 후 주식회사 오앤유 페이먼트(“달카페이“)에 제공
                            합니다. 팩스 또는 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.<br />
                            (4) 계약 시의 민원처리나 이용자의 민원 내용확인, 세부내용 확인과 조사를 위한 연락처,
                            처리결과 통보, 분쟁조정을 위한 기타 고지사항 전달 등을 목적으로 개인정보를 수집하
                            고 처리합니다. 민원처리로 인한 전화문의 시 휴대전화번호, 녹취테이터, 문의 내용을
                            수집할 수 있습니다(소비자의 불만 분쟁 처리로 인한 기록 보관은 3년).<br /><br />
                            2) 회사는 이용자 상호간 및 이용자와 제3자 사이에 발생한 분쟁에 개입하지 않으며, 이로
                            인한 손해를 책임지지 않습니다.<br /><br />
                        </Text>
                        <SubTitle>2. 개인정보 처리방침 및 제3자 개인정보 활용 동의 </SubTitle>
                        <Text>
                            1) 회사는 이용자의 개인정보를 개인정보의 처리목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정등 `개인정보 보호법`제 17조 및 18조에 해당하는 경
                            우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보 주체의 개인정보를 제3자에게
                            제공하지 않습니다.<br /><br />
                            2) 개인정보 제공에 사전 동의한 경우에 한하여 개인정보를 제공하고 있으며, 개인정보의
                            제3자가 제공에 대한 동의를 거부하시는 경우에도 기본 서비스를 이용하고 있습니다. 단
                            이용자의 문의와 불만 처리시 까지 연락처 등의 개인정보 보유로 업무 내용과 위탁하는
                            이용자 서비스 내용을 언제든지 쉽게 확인할 수 있도록 회사의 개인정보 방침에 지속적으
                            로 정보를 공개합니다.<br /><br />
                            3) 회사의 서비스 업무내용이나 변경시 개인정보 처리방침에 따라 지속적으로 공개를 합니
                            다.<br /><br />
                        </Text>
                    </>
                    :
                    <>
                    </>}
                {params.pk == 2 || props?.pk == 2 ?
                    <>

                    </>
                    :
                    <>
                    </>}
            </Wrappers>
        </>
    )
}
export default Policy;